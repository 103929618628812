import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import {
  CompanyContactInfo,
  JourneyAlertResponse,
  LiveTripMonitoringOverview,
  ReferralAssignment,
  ReferralDriverContactInfo,
  WindowWatcherUserListResult,
} from '@/models/dto/TripMonitoring'
import { ApiResult } from '@/models/dto'
import { LiveTripDetailResultDTO } from '@/utils/liveTripTracking'
import { CreateLiveTripJourneyEventRequest, CreateLiveTripJourneyEventResponse } from '@/models/dto/LiveTripEvent'

const httpService: HttpService = new HttpService()

export const getWindowWatcherUsers = async (): Promise<
  AxiosResponse<WindowWatcherUserListResult>
> => {
  const url = `https://${baseUrl()}/user/windowWatchers`
  return httpService.get(url)
}

export const assignReferralToRepresentative = async (
  userId: number,
  referralIds: number[]
): Promise<AxiosResponse<ApiResult>> => {
  const referralAssignment: ReferralAssignment = {
    userId,
    referralIds,
  }
  const url = `https://${baseUrl()}/tripMonitoring/referral/representative`
  return httpService.post(url, referralAssignment)
}

export const getCompanyContactInfo = async (
  companyId: number
): Promise<AxiosResponse<CompanyContactInfo>> => {
  const url = `https://${baseUrl()}/tripMonitoring/company/${companyId}/contactInfo`
  return httpService.get(url)
}

export const getReferralDriverContactInfo = async (
  companyId: number,
  referralId: number
): Promise<AxiosResponse<ReferralDriverContactInfo>> => {
  const url = `https://${baseUrl()}/tripMonitoring/company/${companyId}/referral/${referralId}/driverContactInfo`
  return httpService.get(url)
}

export const createContactLog = async (payload) => {
  const url = `https://${baseUrl()}/tripMonitoring/referral/contactLog`
  return httpService.post(url, payload)
}

export const upsertReferralAmenityNote = async (referralId, payload) => {
  const url = `https://${baseUrl()}/tripMonitoring/referral/${referralId}/amenityNote`
  return httpService.put(url, payload)
}
export const triggerJourneyEventAlert = async (
  journeyId: string,
  eventId: string
): Promise<AxiosResponse<JourneyAlertResponse>> => {
  const url = `https://${baseUrl()}/journey/${journeyId}/event/${eventId}/alert/trigger`
  return httpService.post(url, {})
}

export const snoozeJourneyEventAlert = async (
  journeyId: string,
  eventId: string
): Promise<AxiosResponse<JourneyAlertResponse>> => {
  const url = `https://${baseUrl()}/journey/${journeyId}/event/${eventId}/alert/snooze`
  return httpService.post(url, {})
}

export const dismissJourneyEventAlert = async (
  journeyId: string,
  eventId: string
): Promise<AxiosResponse<JourneyAlertResponse>> => {
  const url = `https://${baseUrl()}/journey/${journeyId}/event/${eventId}/alert/dismiss`
  return httpService.post(url, {})
}

export const getLiveTripDetail = async(referralId: string): Promise<AxiosResponse<LiveTripDetailResultDTO>> => {
  const url = `https://${baseUrl()}/tripMonitoring/live/${referralId}`
  return httpService.get(url)
}

export const getLiveTripMonitoringOverview = async (): Promise<
  AxiosResponse<LiveTripMonitoringOverview>
> => {
  const url = `https://${baseUrl()}/tripMonitoring/live/overview`
  return httpService.get(url)
}

export const createLiveTripEvent = async (journeyId: string, payload: CreateLiveTripJourneyEventRequest): Promise<AxiosResponse<CreateLiveTripJourneyEventResponse>> => {
  const url = `https://${baseUrl()}/journey/${journeyId}/event`
  return httpService.post(url, payload)
}
